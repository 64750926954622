// Imports

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import loaderStyles from "./overlay-loader.module.css";
import Loader from 'react-loader-spinner';
import React, { Component } from 'react';
import { navigate } from "@reach/router";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { endLoading, redirectTo } from '../../actions/ui';

// Loader

class OverlayLoader extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState() {
        return {
            redirecting: false,
        }
    }

    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        redirectEndpoint: PropTypes.string,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        redirectTo: PropTypes.func.isRequired,
        endLoading: PropTypes.func.isRequired,
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isLoading !== this.props.isLoading) {
            let shouldPreventBodyScroll = false;
            if (this.props.isLoading === true) {
                shouldPreventBodyScroll = true;
            }
            if (shouldPreventBodyScroll === true) {
                document.body.classList.add('overflowHidden');
            } else {
                document.body.classList.remove('overflowHidden');
            }
        }
        if (prevProps.redirectEndpoint !== this.props.redirectEndpoint) {
            this.setState({ redirecting: true})
            if (this.props.redirectEndpoint === null) {
                this.setState({ redirecting: false})
            }
        }
    }

    delayRedirect = to => {
        const { redirecting } = this.state;
        if (redirecting === false && to !== null) {
            setTimeout(() => {
                navigate(to);
                this.props.endLoading();
                this.props.redirectTo({endpoint: null});
            }, 2500);
        }
    };

    render() {
        let { title, subtitle, isLoading, redirectEndpoint } = this.props;
        if (isLoading === false) {
            return null;
        }
        if (redirectEndpoint !== null) {
            this.delayRedirect(redirectEndpoint);
        }
        return (
            <div className={loaderStyles.loaderContainer}>
                <div className={loaderStyles.loaderAnimationSubcontainer}>
                    <Loader type="TailSpin" color="#008C4D" height={70} width={70} visible={isLoading} />
                    <h3 className={loaderStyles.loaderTitle}>{title}</h3>
                    <p className={loaderStyles.loaderSubtitle}>{subtitle}</p>
                </div>  
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.uiReducer.isLoading,
    redirectEndpoint: state.uiReducer.redirectEndpoint,
    title: state.uiReducer.loadingTitle,
    subtitle: state.uiReducer.loadingSubtitle
})
  
OverlayLoader.defaultProps = {
    title: 'Submitting',
    subtitle: 'Please be patient while we submit your information.',
    isLoading: false,
    redirectEndpoint: null
}

export default connect(
    mapStateToProps,
    { endLoading, redirectTo }
)(OverlayLoader);

