// Imports

import { useStaticQuery, graphql } from "gatsby"

// Hook

export const ContactInfoHook = () => {
  const contactInfo = useStaticQuery(
    graphql`
      query contactInfoQuery {
        allFile(filter: {sourceInstanceName: {eq: "content"} name: {eq: "contact-page"}}) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  email
                  phone
                  fax
                  addressLine1
                  addressLine2
                  city
                  state
                  zip
              }
            }
          }
        }
      }
    }
    `
  )
  return contactInfo.allFile.edges[0].node.childMarkdownRemark.frontmatter
}