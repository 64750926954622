// Imports

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import headerStyles from "./header.module.css"
import logo from "../../images/logo-nav.png"

// Header

const Header = ({ siteTitle }) => (
  <header className={headerStyles.header}>
    <div className={headerStyles.widthContainer}>
      <Link to="/">
        <img className={headerStyles.logo} src={logo} alt="Ace Analytical" />
      </Link>
      <nav className={headerStyles.nav}>
        <div className={headerStyles.links}>
          <Link to="/about/" className={headerStyles.link}>About</Link>
          <Link to="/faq/" className={headerStyles.link}>FAQ</Link>
          <Link to="/research/" className={headerStyles.link}>Articles</Link>
          <Link to="/services/" className={headerStyles.link}>Services</Link>
          <Link to="/contact/" className={headerStyles.link}>Contact</Link>
        </div>
        <Link to="/get-started/" target="_blank" rel="noopener noreferrer" className={headerStyles.getStartedButton}>Get Started</Link>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
