// Imports

import { 
    BEGIN_LOADING,
    END_LOADING,
    REDIRECT_TO
  } from './types';
  
// Actions

// Loading
export const beginLoading = options => (dispatch) => {
dispatch({
    type: BEGIN_LOADING,
    payload: options
});
}
export const endLoading = options => (dispatch) => {
dispatch({
    type: END_LOADING,
    payload: options
});
}

// Redirect
export const redirectTo = options => (dispatch) => {
dispatch({
    type: REDIRECT_TO,
    payload: options
})
}
