/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import { connect } from 'react-redux';
import PropTypes from "prop-types"

import Header from "./header/header"
import Footer from "./footer/footer"

import ageStyles from "../styles/pages/age.module.css"
import OverlayLoader from './loader/overlay-loader'
import "./overrides.css"
import "./layout.css"

class Layout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOver21: typeof window !== 'undefined' && localStorage.getItem('isOver21'),
      children: props.children
    }
  }

  onClickYes = e => {
    e.preventDefault();
    typeof window !== 'undefined' && localStorage.setItem('isOver21', true);
    this.setState({ isOver21: true})
  };

  onClickNo = e => {
    e.preventDefault();
    const referrer = document.referrer.split('/')[2]
    if (referrer !== null && 
        referrer !== 'localhost:8000' &&
        referrer !== 'aceanalytical.com') {
       window.location.replace(document.referrer);
     } else {
      window.location.replace('https://www.google.com')
     }
  };

  render() {
    const isOver21 = typeof window !== 'undefined' && localStorage.getItem('isOver21');
    const over21 = (
      <>
        <OverlayLoader />
        <Header siteTitle="Ace Analytical Laboratory" />
        <div className="container">
          <main style={{ width: '100%' }}>{this.state.children}</main>
        </div>
        <div className="over21Banner">
          <p className="over21Paragraph">For use only by adults 21 years of age and older | Keep out of reach of children</p>
        </div>
        <Footer />
      </>
    )
    const under21 = (
      <>
        <div className="container">
          <div className={ageStyles.container}>
            <img alt="Ace" src="/assets/ace-icon.png" className={ageStyles.logo} />
            <h2 className={ageStyles.header}>Are you over 21 years of age?</h2>
            <div className={ageStyles.buttons}>
              <button className={ageStyles.yesButton} onClick={this.onClickYes}>Yes</button>
              <button className={ageStyles.noButton} onClick={this.onClickNo}>No, take me back</button>
            </div>
          </div>
        </div>
      </>
    )
    return <>{ isOver21 ? over21 : under21 }</>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(
  null,
  {}
)(Layout);
