// Imports

import { Link } from "gatsby"
import React from "react"

import { FormsHook } from "../../hooks/forms"
import footerStyles from "./footer.module.css"
import dspLogo from "../../images/dsp-logo.svg"
import logo from "../../images/logo-footer.png"
import { ContactInfoHook } from "../../hooks/contact-information"

// Footer

const Footer = ({ siteTitle }) => {

    // Forms
    const forms = FormsHook()
    const firstFourForms = forms.slice(0, 3)
    const contactData = ContactInfoHook()

    let viewAllForms = null
    if (forms.length > 3) {
      viewAllForms = <Link to="/research/" className={footerStyles.link}>View All Articles</Link>
    }

    return (
      <>
        <div className={footerStyles.footer}>
          <div className={footerStyles.container__top}>
            <div className={footerStyles.widthContainer}>
              <nav className={footerStyles.col__double}>
                <Link to="/">
                  <img className={footerStyles.logo} src={logo} alt="Ace Analytical" />
                </Link>
              </nav>
              <nav className={footerStyles.col}>
                <h4 className={footerStyles.col__header}>Navigation</h4>
                <Link to="/" className={footerStyles.link}>Home</Link>
                <Link to="/about/" className={footerStyles.link}>About</Link>
                <Link to="/contact/" className={footerStyles.link}>Contact</Link>
                <Link to="/faq/" className={footerStyles.link}>FAQ</Link>
              </nav>
              <nav className={footerStyles.col}>
                <h4 className={footerStyles.col__header}>
                  <br />
                </h4>
                <Link to="/research/" className={footerStyles.link}>Articles</Link>
                <Link to="/services/" className={footerStyles.link}>Services</Link>
              </nav>
              <nav className={footerStyles.col}>
                <h4 className={footerStyles.col__header}>Articles</h4>
                {firstFourForms.map(form => {
                  return <Link to="/research/" className={footerStyles.articleLink} key={form.node.childMarkdownRemark.frontmatter.title}>{form.node.childMarkdownRemark.frontmatter.title}</Link>
                })}
                { viewAllForms }
              </nav>
              <nav className={footerStyles.col}>
                <h4 className={footerStyles.col__header}>Contact</h4>
                <a href={"tel:" + contactData.phone} className={footerStyles.link}>{contactData.phone}</a>
                <a href={"mailto:" + contactData.email} className={footerStyles.link}>{contactData.email}</a>
                <a href="https://www.google.com/maps?q=7151+Cascade+Valley+Ct.+Suite+210+Las+Vegas,+NV+89128&um=1&ie=UTF-8&sa=X&ved=0ahUKEwjisZzygJ_lAhU1GDQIHTPdAwEQ_AUIEigB" className={footerStyles.link} target="_blank" rel="noopener noreferrer">
                  {contactData.addressLine1}<br />{contactData.addressLine2}<br/>{contactData.city}, {contactData.state} {contactData.zip}
                </a>
              </nav>
            </div>
          </div>
          <div className={footerStyles.container__bottom}>
            <div className={footerStyles.widthContainer}>
              <div className={footerStyles.socials}>
                <a className={footerStyles.socials__link} href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                </a>
              </div>
              <div className={footerStyles.misc}>
                <p className={footerStyles.copyright}>© 2019 Ace Analytical Laboratory</p>
                <a className={footerStyles.dsp} href="https://www.deepspaceprogram.com/" target="_blank" rel="noopener noreferrer">
                  <img src={dspLogo} className={footerStyles.dsp_logo} alt="Deep Space Program"></img>
                  Created by Deep Space Program
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default Footer
